import React, { useCallback, useState } from 'react';
import { useNotify } from 'react-admin';
import EmailSignInCallbackForm from '#/components/authentification/EmailSignInCallbackForm';
import EmailSignInForm from '#/components/authentification/EmailSignInForm';
import useWaitForIdentity from '#/lib/useWaitForIdentity';
import { config } from '@jucy-askja/common/config';
import { Alert, AlertTitle, Box, Button, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import { LoginComponent } from 'ra-core';
import { LoginProps } from 'ra-ui-materialui';
import { OAuthProvider, getAuth, isSignInWithEmailLink, signInWithPopup } from 'firebase/auth';
import 'firebase/compat/auth';
import Container from './authentification/Container';

const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
    tenant: config.msConfig.tenantId,
});

const CustomLoginPage: React.FC<LoginProps> = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showSignUp, setShowSignUp] = useState(false);
    const auth = getAuth();
    const [isSignInWithEmailCallback] = useState(() => isSignInWithEmailLink(auth, window.location.href));
    const handleShowSignUpForm = useCallback(() => {
        setShowSignUp(true);
    }, []);

    const waitForIdentity = useWaitForIdentity();

    const handleStaffLogin = async () => {
        try {
            setLoading(true);
            const result = await signInWithPopup(auth, provider);
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential?.accessToken;
            const idToken = credential?.idToken;
            if (!accessToken || !idToken) {
                notify('Invalid email or password');
                return;
            }
            await waitForIdentity();
            window.location.href = '/';
        } catch (error) {
            setLoading(false);
            notify('Invalid email or password');
            setError(error?.message || 'Unknown error');

        }
    };

    if (error) {
        return (
            <Container>
                <Box paddingBottom={2}>
                    <Alert severity="error">
                        <AlertTitle>Authentication failure</AlertTitle>
                        <Box sx={{ py: 1 }}>There was an error while trying to authenticate:</Box>
                        <pre>{error || 'Unknown error'}</pre>
                    </Alert>
                </Box>
                <Box paddingBottom={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        Retry
                    </Button>
                </Box>
            </Container>
        );
    }

    if (loading) {
        return (
            <Container>
                <CircularProgress className="animated fadeIn" sx={{ margin: '20px' }} />
            </Container>
        );
    }

    if (isSignInWithEmailCallback) {
        return (
            <Container>
                <Button sx={{ alignSelf: 'self-start' }} size="small" onClick={() => (window.location.href = '/')}>
                    &lt; Back
                </Button>
                <Typography variant="h6">Sign in with personal email</Typography>
                <EmailSignInCallbackForm />
            </Container>
        );
    }

    return (
        <Container>
            {showSignUp ? (
                <>
                    <Button sx={{ alignSelf: 'self-start' }} size="small" onClick={() => setShowSignUp(false)}>
                        &lt; Back
                    </Button>
                    <Typography variant="h6">Sign in with personal email</Typography>
                    <EmailSignInForm />
                </>
            ) : (
                <>
                    <Typography variant="h6">Welcome!</Typography>
                    Log in with
                    <Stack sx={{ alignItems: 'center' }}>
                        <Button onClick={handleStaffLogin} variant="contained" sx={{ margin: 2, width: '160px' }}>
                            Work email
                        </Button>
                        <Divider flexItem />
                        or
                        <Divider flexItem />
                        <Button onClick={handleShowSignUpForm} variant="contained" sx={{ margin: 2, width: '160px' }}>
                            Personal email
                        </Button>
                    </Stack>
                </>
            )}
        </Container>
    );
};

export default CustomLoginPage as LoginComponent;
