import React from 'react';
import { useForm } from 'react-hook-form';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Alert, AlertTitle, Box, Button, TextField, Typography } from '@mui/material';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

export type EmailSignInCallbackFormValues = {
    email: string;
};

const EmailSignInForm: React.FC = () => {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<EmailSignInCallbackFormValues>();
    const [success, setSuccess] = React.useState(false);
    const auth = getAuth();
    const onSubmit = async (data: EmailSignInCallbackFormValues) => {
        try {
            await sendSignInLinkToEmail(auth, data.email, {
                url: window.origin,
                handleCodeInApp: true,
            });
            window.localStorage.setItem('emailForSignIn',data.email);
            setSuccess(true);
        } catch (e) {
            setError('root.serverError', {
                message: e.message,
            });
        }
    };
    if (success) {
        return (
            <Box sx={{ maxWidth: '235px', py: 1 }}>
                <Alert icon={<CheckCircleOutlineIcon fontSize="inherit" />} severity="success">
                    <AlertTitle> Check your inbox!</AlertTitle>
                    We&#39;ve sent you an email with a link to sign in. Simply click the link, and you&#39;ll be automatically signed in.
                    <br />
                    <br />
                    Didn’t receive the email? Check your spam or junk folder, or{' '}
                    <Button sx={{ display: 'inline', p: 0 }} variant="text">
                        resend the email
                    </Button>
                </Alert>
                <Typography></Typography>
            </Box>
        );
    }
    return (
        <Box sx={{ maxWidth: '200px', pt: 1 }} component="form" onSubmit={handleSubmit(onSubmit)}>
            Please enter your email address to sign in:
            <TextField
                label="Email"
                type="email"
                margin="normal"
                required
                variant="filled"
                {...register('email', { required: true, maxLength: 30 })}
                error={Boolean(errors.email)}
                helperText={errors.email?.type === 'required' ? 'This is required' : ''}
            />
            <Button type="submit" variant="contained" sx={{ mb: 2 }}>
                Submit
            </Button>
            {errors.root?.serverError ? (
                <Alert severity="error">
                    <AlertTitle>Authentication failure</AlertTitle>
                    <Box sx={{ py: 1 }}>There was an error while trying to authenticate:</Box>
                    <pre>{errors.root?.serverError?.message || 'Failed to send you a confirmation email'}</pre>
                </Alert>
            ) : null}
        </Box>
    );
};

export default EmailSignInForm;
