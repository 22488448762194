import { isCompetencyIgnored } from '#/lib/ignoreCompetency';
import { Competency } from '@jucy-askja/common/schemas/Competency';
import { Review } from '@jucy-askja/common/schemas/Review';
import { ReviewStatus } from '@jucy-askja/common/schemas/ReviewStatus';

export const getCurrentReviewStatus = (review: Review):ReviewStatus => {
    const reviewStatus = review.status;
    if (!review.competencies || reviewStatus !== 'Completed') {
        return review.status;
    }
    const employeeComplete = review.competencies
        .filter((c) => !isCompetencyIgnored(c))
        .every((competency: Competency) => {
            const hasTitle = Boolean(competency?.editTitle ? competency?.title?.trim():true);
            if (!hasTitle) {
                return false;
            }
            const hasDescription = Boolean(!competency?.editDescription ? competency?.description?.trim():true);
            if (!hasDescription) {
                return false;
            }
            const hasCategory = Boolean(!competency?.editCategory ? competency?.competencyCategory?.trim():true);
            if (!hasCategory) {
                return false;
            }
            return true;
        });
    if (!employeeComplete) {
        return 'Pending Employee';
    }
    const managerApproved = review.competencies.filter((c) => !isCompetencyIgnored(c)).every((competency: Competency) => competency.managerApproved === 'Approved');
    const hrApproved = review.competencies.filter((c) => !isCompetencyIgnored(c)).every((competency: Competency) => competency.hrApproved === 'Approved');
    if (!managerApproved){
        return 'Pending Manager';
    }
    if (!hrApproved){
        return 'Pending HR';
    }

    return review.status;
};
