import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useWaitForIdentity from '#/lib/useWaitForIdentity';
import { Alert, AlertTitle, Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useLogin } from 'ra-core';
import { getAuth, signInWithEmailLink } from 'firebase/auth';

export type EmailSignInCallbackFormValues = {
    email: string;
};

const EmailSignInCallbackForm: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<EmailSignInCallbackFormValues>();
    const auth = getAuth();
    const login = useLogin();
    const [loading, setLoading] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [error, setError] = React.useState('');
    const waitForIdentity = useWaitForIdentity();

    const doSignIn = useCallback(
        async (email: string) => {
            try {
                const result = await signInWithEmailLink(auth, email, window.location.href);
                setLoading(true);
                await login(result.user);
                await waitForIdentity();
                window.localStorage.removeItem('emailForSignIn');
                window.location.href = '/';
            } catch (e) {
                setLoading(false);
                setError(e?.message || 'Unknown error');
            }
        },
        [auth, login, waitForIdentity]
    );

    useEffect(() => {
        const email = window.localStorage.getItem('emailForSignIn');
        setEmail(email as string);
        setLoading(false);
        if (email) {
            doSignIn(email);
        }
    }, [doSignIn]);

    const onSubmit = async (data: EmailSignInCallbackFormValues) => {
        await doSignIn(data.email);
    };

    const isErrors = Boolean(errors.email);
    const helpText = errors.email?.type === 'required' ? 'This is required' : '';
    if (error) {
        return (
            <Box sx={{ py: 2 }}>
                <Alert severity="error">
                    <AlertTitle>Authentication failure</AlertTitle>
                    <Box sx={{ py: 1 }}>There was an error while trying to authenticate:</Box>
                    <pre>{error}</pre>
                </Alert>
            </Box>
        );
    }
    if (loading || email) {
        return <CircularProgress className="animated fadeIn" sx={{ margin: '20px' }} />;
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="body2" sx={{ mt: 2 }}>
                Session expired, please renter your email address
            </Typography>
            <TextField
                label="Email"
                type="email"
                margin="normal"
                required
                fullWidth
                variant="filled"
                {...register('email', { required: true, maxLength: 30 })}
                error={isErrors}
                helperText={helpText}
            />
            <Button type="submit" variant="contained" sx={{ mb: 2 }}>
                Submit
            </Button>
        </form>
    );
};

export default EmailSignInCallbackForm;
