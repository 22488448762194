import * as React from 'react';
import { useGetList } from 'react-admin';
import { useGetUserProfile } from '#/hooks/useGetUserProfile';
import { Employee } from '@jucy-askja/common/schemas/Employee';
import { Box, CircularProgress, Typography } from '@mui/material';

const ReviewList = React.lazy(() => import('#/resources/Review/ReviewList'));

const Dashboard = () => {
    const { data: profile } = useGetUserProfile();
    const showEmployeeReview = ['manager', 'admin'].includes(profile?.role || '');
    return (
        <>
            <Box sx={{ marginTop: 10, marginX: 20 }}>
                <Typography variant="h4">Your KPIs</Typography>
                <ReviewList resource="selfReview" reviewType="selfReview" />
            </Box>
            {showEmployeeReview && profile ? <EmployeeKpis manager={profile} /> : null}
        </>
    );
};

const EmployeeKpis = ({ manager }: { manager: Employee }) => {
    const { isLoading, data } = useGetList('employee', {
        filter: { manager: manager?.id },
    });
    if (!isLoading && !data?.length) {
        return null;
    }
    return (
        <>
            <Box sx={{ marginTop: 10, marginX: 20, mb: 5 }}>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress className="animated fadeIn" sx={{ margin: '20px' }} />
                    </Box>
                ) : (
                    <>
                        <Typography variant="h4">Your Employee KPIs</Typography>
                        <ReviewList resource="employeeReview" reviewType="employeeReview" />
                    </>
                )}
            </Box>
        </>
    );
};

export default Dashboard;
