import { getCurrentReviewStatus } from '#/lib/getCurrentReviewStatus';
import { mergeCompetencies } from '@jucy-askja/common/lib/mergeCompetencies';
import { sortCompetencies } from '@jucy-askja/common/lib/sortCompentcies';
import { Review } from '@jucy-askja/common/schemas/Review';
import { Template } from '@jucy-askja/common/schemas/Template';
import { ResourceCallbacks, ResourceCallbacksValue } from 'ra-core';

const reviewAfterReadFn: ResourceCallbacksValue<Review> = async (review: Review, dataProvider) => {
    const [coreTemplateResult, functionalTemplateResult] = await Promise.allSettled([
        dataProvider.getOne<Template>('template', {
            id: review.coreTemplateId,
        }),
        dataProvider.getOne<Template>('template', {
            id: review.functionalTemplateId,
        }),
    ]);
    const coreTemplate = coreTemplateResult.status === 'fulfilled' ? coreTemplateResult.value.data : undefined;
    const functionalTemplate = functionalTemplateResult.status === 'fulfilled' ? functionalTemplateResult.value.data : undefined;

    const coreIds = coreTemplate?.competencies?.map((c) => c.id) || [];
    const functionalIds = functionalTemplate?.competencies?.map((c) => c.id) || [];
    const coreCompetenciesFromTemplate = (coreTemplate?.competencies || []).map((c) => ({
        ...c,
        templateId: coreTemplate?.id,
    }));
    const functionalCompetenciesFromTemplate = (functionalTemplate?.competencies || []).map((c) => ({
        ...c,
        templateId: functionalTemplate?.id,
    }));
    const competencies = mergeCompetencies(review.competencies||[], [...coreCompetenciesFromTemplate, ...functionalCompetenciesFromTemplate]);
    const coreCompetencies = competencies.filter((c) => coreIds.includes(c.id));
    const functionalCompetencies = competencies.filter((c) => functionalIds.includes(c.id));
    const rest = competencies.filter((c) => !coreIds.includes(c.id) && !functionalIds.includes(c.id));
    review.competencies = [...sortCompetencies(coreCompetencies), ...sortCompetencies(functionalCompetencies), ...sortCompetencies(rest)];
    review.status = getCurrentReviewStatus(review);
    return review;
};

export const reviewAfterRead: ResourceCallbacks[] = [
    { resource: 'selfReview', afterRead: reviewAfterReadFn },
    { resource: 'employeeReview', afterRead: reviewAfterReadFn },
    { resource: 'hrReview', afterRead: reviewAfterReadFn },
    { resource: 'review', afterRead: reviewAfterReadFn },
];
