import { Config } from './types/Config.ts';

export const config: Config = {
    firebaseConfig: {
        apiKey: 'AIzaSyAB2sOi8FJlfznjci4kwD5mt0kdWfPY6pY',
        authDomain: 'r2e.jucy.com',
        projectId: 'jucy-askja',
        storageBucket: 'jucy-askja.appspot.com',
        messagingSenderId: '932290405279',
        appId: '1:932290405279:web:b1353abcc8fe621ea753aa',
    },
    msConfig: {
        tenantId: '388a7ead-88d2-4311-aaca-8af448624ddd',
        clientId: '68971ec6-8bba-4a15-9acd-007c8c2ad929',
    },
};
