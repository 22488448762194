import { stripHtml } from '@jucy-askja/common/lib/stripHtml';
import { Competency } from '@jucy-askja/common/schemas/Competency';

export const isCompetencyIgnored = (competency: Competency) => {
    const isTemplate = competency?.source === 'template';
    const isTitleReadOnly = !competency?.editTitle && isTemplate;
    const isDescriptionReadOnly = !competency?.editDescription && isTemplate;
    const isCategoryReadOnly = !competency?.editCategory && isTemplate;
    if (isTitleReadOnly && !competency?.title?.trim()) {
        return true;
    }

    if (isDescriptionReadOnly && !stripHtml(competency?.description?.trim())) {
        return true;
    }
    if (isCategoryReadOnly && !competency?.competencyCategory?.trim()) {
        return true;
    }
    return false;
};
