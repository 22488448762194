import React from 'react';
import { useGetIdentity } from 'react-admin';
import { Navigate } from 'react-router-dom';
import { useGetUserProfile } from '#/hooks/useGetUserProfile';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { ErrorComponent } from './ErrorComponent';
import RefreshIcon from '@mui/icons-material/Refresh';
export const ProfileLoader = ({ children }: { children?: React.ReactNode }) => {
    const { data: identity, isLoading: identityLoading, error: identityError } = useGetIdentity();
    const { data: profile, isLoading: isProfileLoad, isFetching, error: profileError, refetch } = useGetUserProfile();
    const [hasRefetched, setHasRefetched] = React.useState(false);
    if (identityLoading || isProfileLoad) {
        return (
            <Box display="flex" justifyContent="center" padding={2} width="100%">
                <CircularProgress />
            </Box>
        );
    }
    if (identityError) {
        return <ErrorComponent error={identityError} />;
    }
    if (!identity) {
        return <Navigate to="/login" />;
    }
    if (profileError) {
        return <ErrorComponent error={profileError} />;
    }
    if (!profile) {
        return (
            <Paper sx={{ mt: 2, p: 2 }}>
                <Typography variant="h6" color="error">
                    Employee Record Not Found
                </Typography>
                <Typography>

                    {identity.email? `Sorry, we couldn't find an employee record matching the email address '${identity.email}'.` : 'Sorry, we couldn\'t find your employee record.'}

                    <br/>Please contact your manager for assistance or try signing in with an alternate email.</Typography>
                <LoadingButton
                    loading={isFetching}
                    disabled={isFetching}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    loadingPosition="end"
                    endIcon={<RefreshIcon />}
                    onClick={async () => {
                        await refetch();
                        setHasRefetched(true);
                    }}
                >
                    Retry
                </LoadingButton>
                {hasRefetched && !isFetching ? (
                    <Alert variant="filled" severity="error" sx={{ mt: 2 }}>
                        The employee record is still unavailable.<br/>If this issue persists, please contact your manager for further assistance.
                    </Alert>
                ) : null}
            </Paper>
        );
    }
    return children;
};
